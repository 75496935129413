import React from 'react'

import SEO from '../../components/SEO'

import { HeroImage } from '../../components/homeHeroImage'
import HomeIntro from '../../components/homeIntro'
import HomeSectionsIntro from '../../components/homeSectionsIntro'
import ContactFormNew from '../../components/contactFormNew'
import SlyleticaForCreators from '../../components/slyleticaForCreators'
import Illustrations from '../../components/illustrations'
import ProcessBlock from '../../components/processBlock'
import FAQs from '../../components/faqs'
import ShowcaseSlider from '../../components/showcaseSlider'
import Testimonial from '../../components/testimonialBlock'

const Creators = ({ pageContext }) => {
	const {
		page: { title, creatorsFields },
	} = pageContext

	const {
		creatorsHeaderHeroImage,
		creatorsHeaderHeroImageMobile,
		creatorsHeaderCopy,
		creatorsHeaderCta,
		creatorsIntroCopy,
		creatorsBackgroundColour,
		slyForCreatorsHeading,
		creatorsImageCopyRepeater,
		creatorsShowcaseSliderRepeater,
		illustrationsRepeater,
		creatorsTestimonialSlider,
		creatorsProcessHeading,
		creatorsProcessRepeater,
		creatorsFaqsHeading,
		creatorsFaqsRepeater,
		creatorsResponsibilityContentPosition,
		creatorsResponsibilityBackgroundImage,
		creatorsResponsibilityHeading,
		creatorsResponsibilityCopy,
		creatorsResponsibilityLink,
		creatorsContactBackgroundColour,
		creatorsContactHeading,
		creatorsContactCopy,
	} = creatorsFields

	return (
		<>
			<SEO title={title} />
			<HeroImage
				image={creatorsHeaderHeroImage}
				mobileImage={creatorsHeaderHeroImageMobile}
				copy={creatorsHeaderCopy}
				cta={creatorsHeaderCta}
			/>
			<HomeIntro
				copy={creatorsIntroCopy}
				backgroundColour={creatorsBackgroundColour}
			/>
			<Illustrations repeater={illustrationsRepeater} />
			<Testimonial testimonials={creatorsTestimonialSlider} />
			<ShowcaseSlider brands={creatorsShowcaseSliderRepeater} />
			<ProcessBlock
				heading={creatorsProcessHeading}
				repeater={creatorsProcessRepeater}
			/>
			<SlyleticaForCreators
				heading={slyForCreatorsHeading}
				repeater={creatorsImageCopyRepeater}
			/>
			<FAQs heading={creatorsFaqsHeading} faqs={creatorsFaqsRepeater} />
			<HomeSectionsIntro
				heading={creatorsResponsibilityHeading}
				copy={creatorsResponsibilityCopy}
				link={creatorsResponsibilityLink}
				backgroundImage={creatorsResponsibilityBackgroundImage}
				contentPosition={creatorsResponsibilityContentPosition}
				extraHeadingWidth={false}
			/>
			<ContactFormNew
				heading={creatorsContactHeading}
				copy={creatorsContactCopy}
				backgroundColour={creatorsContactBackgroundColour}
			/>
		</>
	)
}

export default Creators
